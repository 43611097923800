const imageList = [
    {
        name: 'Tree',
        src: require('../../assets/Tree.png')
    },
    {
        name: 'Shrub',
        src: require('../../assets/Shrub.png')
    }
]

export default imageList;