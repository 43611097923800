<template>
  <DrawingPage></DrawingPage>
</template>

<script>
import DrawingPage from './components/DrawingPage.vue'

export default {
  name: 'App',
  components: {
    DrawingPage
  }
}
</script>